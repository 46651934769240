export const QUERY_KEYS = Object.freeze({
  //Auth
  CHECK_ROLE: 'check_role',

  //For Roll
  ACCOUNTANT: 'accountant',
  CONSULTANT: 'consultant',

  //
  SEARCH_TASK_HISTORY: 'search_task_history',
  //Salary statistics
  SALARY_STATISTICS: 'salary_statistics',
  //Industry
  SUBJECTS: 'subjects',
  INDUSTRIES: 'industries',
  DEPARTMENTS: 'departments',
  EXPERIENCES: 'experiences',
  CONNECT_DEPARTMENT_SUBJECT: 'connect_department_subject',
  //Location
  PROVINCES: 'provinces',
  DISTRICTS: 'districts',
  WARDS: 'wards',
  //Term and policy
  TERM_AND_POLICIES: 'term_and_policies',
  TERM_AND_POLICY: 'term_and-policy',
  //User
  USERS: 'users',
  USER: 'user',
  //Cv
  CVS: 'cvs',
  CV: 'cv',
  CV_REQUESTS: 'cv_requests',
  CV_REQUEST: 'cv_request',
  //Class
  CLASSES: 'classes',
  CLASS: 'class',
  CLASS_SUM_FOR_TEACHER: 'class_sum_for_teacher',
  CLASS_APPLICATION_INFORMATIONS: 'class_application_informations',
  CLASS_APPLICATION_INFORMATION: 'class_application_information',
  CLASS_FAIL_REASON: 'class_fail_reason',
  //Conversations
  CONVERSATIONS: 'conversations',
  CONVERSATION: 'conversation',
  //Messages
  MESSAGES: 'messages',
  //Task history
  OVERVIEW_TASK_HISTORY: 'overview_task_history',
  //Transactions
  DEPOSIT_TRANSACTIONS: 'deposit_transactions',
  WITHDRAW_TRANSACTIONS: 'withdraw_transactions',
  TRANSACTION: 'transaction',
  CHILD_TRANSACTION: 'child_transaction',
  TRANSACTION_VERIFICATION_FAIL_REASON: 'transaction_verification_fail_reason',
  TRANSACTIONS: 'transactions',
  SUP_TRANSACTIONS_BY_ACCT: 'sup_transactions_by_acct',
  VERIFY_PENDING_COMPANY_ACCOUNT_WITHDRAW_TRANSACTION:
    'verify_pending_company_account_withdraw_transaction',
  VERIFY_PENDING_COMPANY_ACCOUNT_DEPOSIT_TRANSACTION:
    'verify_pending_company_account_deposit_transaction',
  COMPANY_ACCOUNT_WITHDRAW_TRANSACTIONS:
    'company_account_withdraw_transactions',
  COMPANY_ACCOUNT_DEPOSIT_TRANSACTIONS: 'company_account_deposit_transactions',
  TRANSACTION_CHART: 'transaction_chart',
  TRANSACTION_STATISTIC_OVERVIEW: 'transaction_statistic_overview',
  TRANSACTION_OVERVIEW: 'transaction_overview',

  //Transaction verification
  TRANSACTION_VERIFICATIONS: 'transaction_verifications',
  TRANSACTION_VERIFICATION: 'transaction_verification',

  //Transaction support
  TRANSACTION_SUPPORTS: 'transaction_supports',
  TRANSACTION_SUPPORT: 'transaction_support',

  //Transaction support request
  TRANSACTION_SUPPORT_REQUESTS: 'transaction_support_requests',
  TRANSACTION_SUPPORT_REQUEST: 'transaction_support_request',
  TRANSACTION_SUPPORT_REQUEST_BY_UNDEFINED_TRANSACTION_ID:
    'transaction_support_request_by_undefined_transaction_id',
  TRANSACTION_SUPPORT_REQUEST_OVERVIEW: 'transaction_support_request_overview',

  //Transaction report
  TRANSACTION_REPORTS: 'transaction_reports',
  TRANSACTION_REPORT: 'transaction_report',
  TRANSACTION_REPORT_FAIL_REASON: 'transaction_report_fail_reason',
  OVER_AND_UNDER_COUNT: 'over_and_under_count',
  //Company transaction
  COMPANY_TRANSACTIONS: 'company_transactions',
  //Undefined transaction
  UNDEFINED_TRANSACTION_GET_ALL_MONEY: 'undefined_transaction_get_all_money',
  UNDEFINED_TRANSACTION: 'undefined_transaction',
  UNDEFINED_TRANSACTIONS: 'undefined_transactions',
  UNDEFINED_TRANSACTION_DELETE_REQUEST: 'undefined_transaction_delete_request',
  UNDEFINED_TRANSACTION_OUT_OF_BAND_REQUEST:
    'undefined_transaction_out_of_band_request',
  SUMMARY_UNDEFINED_TRANSACTION_MONEY: 'summary_transaction_transaction_money',
  HISTORY_SUMMARY_UNDEFINED_TRANSACTION_MONEY:
    'history_summary_transaction_transaction_money',
  //Dashboard filter
  TRANSACTION_DASHBOARD_FILTER_COUNT: 'transaction_dashboard_filter_count',

  //Continue heere
  //Bank account
  BANK_ACCOUNT: 'bank_account',
  BANK_ACCOUNTS: 'bank_accounts',
  IDLE_BANK_ACCOUNTS: 'idle_bank_accounts',
  //BANK
  BANK: 'bank',
  BANKS: 'banks',
  //EWallet account
  E_WALLET_ACCOUNT: 'e_wallet_account',
  E_WALLET_ACCOUNTS: 'e_wallet_accounts',
  IDLE_E_WALLET_ACCOUNTS: 'idle_e_wallet_accounts',
  //BANK
  E_WALLET: 'e_wallet',
  E_WALLETS: 'e_wallets',
  //WALLET
  WALLET: 'wallet',
  WALLETS: 'wallets',
  //Identification
  IDENTIFICATION: 'identification',
  CHECK_IDENTIFICATION: 'check_identification',

  //Company Account Monthly Audit
  COMPANY_ACCOUNT_MONTHLY_AUDIT: 'company_account_monthly_audit',
  COMPANY_ACCOUNT_MONTHLY_AUDIT_STATISTIC:
    'company_account_monthly_audit_statistic',
  COMPANY_ACCOUNT_MONTHLY_AUDIT_CHART: 'company_account_monthly_audit_chart',

  //Company bank account
  COMPANY_BANK_ACCOUNT: 'company_bank_account',

  //Company Bank Account Assignment
  COMPANY_BANK_ACCOUNT_ASSIGNMENT: 'company_bank_account_assignment',

  //Company Ewallet Account
  COMPANY_EWALLET_ACCOUNT: 'company_ewallet_account',

  //Company Ewallet Account Assignment
  COMPANY_EWALLET_ACCOUNT_ASSIGNMENT: 'company_ewallet_account_assignment',

  //--AVAILABLE MONTH
  GET_AVAILABLE_MONTH: 'get_available_month',

  //--RATING SUMMARY
  RATING_SUMMARY: 'rating_summary',

  //WORK SHIFT
  SHIFT_TEMPLATE: 'shift_template',
  SHIFT_TEMPLATES: 'shift_templates',

  DATE_INFO_BY_WEEK: 'date_info_by_week',
  DATE_INFO: 'date_info',

  FULL_TIME_WORK_SHIFT_SCHEDULE: 'full_time_work_shift_schedule',
  FULL_TIME_WORK_SHIFT_SCHEDULES: 'full_time_work_shift_schedules',
  FULL_TIME_WORK_SHIFT_SCHEDULE_REQUEST:
    'full_time_work_shift_schedule_request',
  FULL_TIME_WORK_SHIFT_SCHEDULE_REQUESTS:
    'full_time_work_shift_schedule_requests',
  FULL_TIME_WORK_SHIFT_SCHEDULE_DAY: 'full_time_work_shift_schedule_day',

  FULL_TIME_WORK_SHIFT_SCHEDULE_DAY_SUMMARY:
    'full_time_work_shift_schedule_day_summary',
  FULL_TIME_WORK_SHIFT_SCHEDULE_DAYS: 'full_time_work_shift_schedule_days',

  WORK_SHIFT: 'work_shift',
  WORK_SHIFTS: 'work_shifts',
  GET_BASE_STATIC_WORK_SHIFT: 'get_base_static_work_shift',
  GET_STATIC_SALARY_WORK_SHIFT: 'get_static_salary_work_shift',
  GET_STATIC_ACTUALLY_SALARY_WORK_SHIFT:
    'get_static_actually_salary_work_shift',
  GET_DETAIL_STATIC_WORK_SHIFT: 'get_detail_static_work_shift',

  STAFF_WORK_SHIFT: 'staff_work_shift',

  OFF_COUNT: 'off_count',

  //WORK_ATTENDANCE
  WORK_ATTENDANCE: 'work_attendance',
  WORKING_NOW: 'working_now',
  CHECK_IN_TIME_WORK: 'check_in_time_work',

  //RECRUITMENT
  RECRUITMENT: 'recruitment',
  SEARCH_RECRUITMENT: 'search_recruitment',
  STATIC_RECRUITMENT: 'static_recruitment',

  //RECRUITMENT_APPLICATION
  RECRUITMENT_APPLICATION: 'recruitment_application',
  REJECT_RECRUITMENT_APPLICATION: 'reject_recruitment_application',
  SEARCH_RECRUITMENT_APPLICATION: 'search_recruitment_application',
  DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER:
    'recruitment_application_current_user',
  GET_STATIC_STATUS_RECRUITMENT_APPLICATION:
    'get_static_status_recruitment_application',
  GET_RECRUITMENT_APPLICATION_BY_MANAGE:
    'get_recruitment_application_by_manage',

  //CONTRACT
  CONTRACT: 'contract',
  CONTRACT_CODE: 'contract_code',
  SEARCH_CONTRACT: 'search_contract',
  STATIC_CONTRACT: 'static_contract',

  //CONTRACT HANDOVER
  CONTRACT_HANDOVER: 'contract_handover',
  SEARCH_STAFF_USER_BY_CONTRACT: 'search_staff_user_by_contract',
  GET_STATIC_STAFF_USER_CONTRACT_HANDOVER:
    'get_static_staff_user_contract_handover',

  //SALARY
  SALARY: 'salary',
  GET_SALARY_BY_CURRENT_USER: 'get_salary_by_current_user',

  // KPI
  KPI: 'kpi',
  STATIC_KPI: 'static_kpi',
  SUM_KPI: 'sum_kpi',

  // MAP
  PROVINCE: 'province',
  WARD: 'ward',
  DISTRICT: 'district',

  // EMPLOYEE ADVANCE FEE
  EMPLOYEE_ADVANCE_FEE: 'employee_advance_fee',  
  SEARCH_EMPLOYEE_ADVANCE_FEE: 'employee_advance_fee',
  STATIC_EMPLOYEE_ADVANCE_FEE: 'static_employee_advance_fee',
  GET_EMPLOYEE_ADVANCE_BY_CURRENT_USER: 'get_employee_advance_by_current_user',

  // WORKING RULE
  WORKING_RULE: 'working_rule',

  
  //SALARY REQUEST
  SALARY_REQUEST: 'salary_request',
  SEARCH_SALARY_REQUEST: 'search_salary_request',
  STATIC_SALARY_REQUEST: 'static_salary_request',
});
