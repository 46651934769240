import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IQueryKeyState {
  [index: string]: any[];
}

export enum QueryKeyConstantType {
  SEARCH_RECRUITMENT = 'SEARCH_RECRUITMENT',
  GET_STATIC_STATUS_RECRUITMENT = 'GET_STATIC_STATUS_RECRUITMENT',

  SEARCH_RECRUITMENT_APPLICATION = 'SEARCH_RECRUITMENT_APPLICATION',
  GET_STATIC_STATUS_RECRUITMENT_APPLICATION = 'GET_STATIC_STATUS_RECRUITMENT_APPLICATION',

  SEARCH_CONTRACT = 'SEARCH_CONTRACT',
  GET_STATIC_STATUS_CONTRACT = 'GET_STATIC_STATUS_CONTRACT',

  SEARCH_CONTRACT_HANDOVER_BY_MANAGE = 'SEARCH_CONTRACT_HANDOVER_BY_MANAGE',

  SEARCH_USER_STAFF_BY_CONTRACT = 'SEARCH_USER_STAFF_BY_CONTRACT',
  GET_STATIC_STAFF_USER_CONTRACT_HANDOVER = 'GET_STATIC_STAFF_USER_CONTRACT_HANDOVER',

  DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER = 'DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER',

  GET_STATIC_ALL_KPI = 'GET_STATIC_ALL_KPI',

  SEARCH_EMPLOYEE_ADVANCE_FEE = 'SEARCH_EMPLOYEE_ADVANCE_FEE',
  GET_STATIC_EMPLOYEE_ADVANCE_FEE = 'GET_STATIC_EMPLOYEE_ADVANCE_FEE',

  SEARCH_SALARY_REQUEST = 'SEARCH_SALARY_REQUEST',
  GET_STATIC_SALARY_REQUEST = 'GET_STATIC_SALARY_REQUEST',
}

const initialState: IQueryKeyState = {};

const slice = createSlice({
  name: 'queryKey',
  initialState,
  reducers: {
    setQueryKey: (
      state,
      action: PayloadAction<{
        name: QueryKeyConstantType;
        queryKey: any[];
      }>,
    ) => {
      state[action.payload.name] = action.payload.queryKey;
    },

    removeQueryKey: (
      state,
      action: PayloadAction<{
        name: QueryKeyConstantType;
      }>,
    ) => {
      delete state[action.payload.name];
    },
  },
});

export default slice.reducer;

export const { removeQueryKey, setQueryKey } = slice.actions;
