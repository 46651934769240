export enum MoneyHolderAccountTypeType {
  REVENUE = 'Thu',
  EXPENSES = 'Chi',
}

export type CompanyAccountMonthlyAuditHeader = {
  bankAccountId?: string | number | null;
  ewalletAccountId?: string | number | null;
};

export type AssignMultiStaffAccountantRequest = {
  staffAccountantId: number;
  ewalletAccountIds: number[];
  bankAccountIds: number[];
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};
