import { Box, CircularProgress, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ConversationType, MessageResponse } from 'src/@types/conversation';
import { ConsultantRoleType } from 'src/@types/user';
import {
  WorkAttendance,
  WorkAttendanceStatusType,
} from 'src/@types/work-attendance';
import { audioPath, customIcon } from 'src/assets';
import { IconSVG } from 'src/components/IconSVG';
import Conversation from 'src/components/conversation/Conversation';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { AuthContext } from 'src/contexts/JWTContext';
import { SocketContext } from 'src/contexts/SocketContext';
import { useGetAllConversationsToday } from 'src/hooks/consultant/conversation/useGetAllConversationsToday';
import {
  addChatByKey,
  addConversation,
  addMsg,
  closeAllChats,
  insertConversation,
  removeConversation,
  updateChat,
  updateConversation,
} from 'src/redux/slices/conversations.slice';
import { useDispatch, useSelector } from 'src/redux/store';
import { getDateNow, momentVi } from 'src/utils/date-handle';
import useSound from 'use-sound';
import DrawerHeader from './DrawerHeader';

export default function MessagePopover() {
  const authData = useContext(AuthContext);

  const { contSocket } = useContext(SocketContext);

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState<string>('');
  const [isNewConversation, setIsNewConversation] = useState(false);
  const [play] = useSound(audioPath.ring);
  const [idConversation, setIdConversation] = useState<number>();
  const [attendanceData, setAttendanceData] = useState<
    WorkAttendance | undefined
  >(undefined);

  const {
    data: conversations,
    refetch,
    isError,
  } = useGetAllConversationsToday({ limit, page, cursor, startTime: attendanceData?.startedAt });

  const {
    conversations: conversationsData,
    maxChats,
    chats,
  } = useSelector((state) => state.conversations);

  const dispatch = useDispatch();

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    initialInView: false,
  });

  useEffect(() => {
    const queryKey = [QUERY_KEYS.WORK_ATTENDANCE, QUERY_KEYS.WORKING_NOW];

    const getCacheData = () => {
      const data = queryClient.getQueryData(queryKey) as WorkAttendance;
      if (data) {
        setAttendanceData(data as any);
        console.log(data);
      }
    };

    getCacheData();

    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      const affectedQueryKey = event?.query?.queryKey;

      if (JSON.stringify(affectedQueryKey) === JSON.stringify(queryKey)) {
        getCacheData();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  useEffect(() => {
    if (inView) {
      if (
        conversationsData.length > 0 &&
        conversations?.meta &&
        conversations.meta.currentPage < Number(conversations.meta.totalPages)
      ) {
        setCursor(conversationsData[conversationsData.length - 1].createdAt);
        setPage(conversations.meta.currentPage + 1);
      }
    }
  }, [inView]);

  useEffect(() => {
    if (
      authData &&
      authData.isAuthenticated &&
      authData.userRole == ConsultantRoleType.STAFF &&
      attendanceData &&
      attendanceData.status == WorkAttendanceStatusType.WORKING
    ) {
      setIsLoading(true);
      refetch();
    }
  }, [authData, cursor, page, attendanceData]);

  useEffect(() => {
    if (conversations && !isError) {
      dispatch(addConversation(conversations?.items));
      setIsLoading(false);
    }
  }, [conversations, isError]);

  useEffect(() => {
    if (isNewConversation) {
      play();
      setIsNewConversation(false);
    }
  }, [isNewConversation]);

  useEffect(() => {
    if (
      contSocket &&
      attendanceData &&
      momentVi(attendanceData?.startedAt || '').toDate() <=
        momentVi().toDate() &&
      attendanceData.status == WorkAttendanceStatusType.WORKING
    ) {
      contSocket.on(
        'consultant-alert',
        (data: { conversation: ConversationType }) => {
          dispatch(insertConversation(data.conversation));
          setIsNewConversation(true);
        },
      );
      contSocket.on(
        'alert-to-consultant-to-leave-room',
        (data: { roomId: string; rating: number }) => {
          const conversationId = Number(data.roomId?.split('contact-')[1]);
          dispatch(
            updateConversation({
              id: conversationId,
              conversation: {
                endTime: getDateNow().toISOString(),
              },
            }),
          );
          dispatch(
            updateChat({
              id: conversationId,
              conversation: {
                rating: data.rating,
                endTime: getDateNow().toISOString(),
              },
            }),
          );
        },
      );
      contSocket.on(
        'consultant-joined-conversation',
        (data: MessageResponse) => {
          const conversationId = Number(data.roomId?.split('contact-')[1]);
          dispatch(
            updateConversation({
              id: conversationId,
              conversation: {
                startTime: getDateNow().toISOString(),
              },
            }),
          );
          dispatch(addChatByKey(conversationId));
          dispatch(
            addMsg({
              id: conversationId,
              msg: data.message,
            }),
          );
        },
      );
      contSocket.on('error', (data) => {
        if ((data.name = 'NotFoundException') && idConversation) {
          dispatch(removeConversation(idConversation));
          setIdConversation(undefined);
          enqueueSnackbar(
            'Không tìm thấy phiên cần trợ giúp của khách hàng này, vì đã có nhân viên khác tiếp nhận, xử lý',
            { variant: 'error' },
          );
        }
      });
    }
    return () => {
      if (contSocket) {
        contSocket.off('consultant-alert');
        contSocket.off('consultant-joined-conversation');
        contSocket.off('error');
        contSocket.off('alert-to-consultant-to-leave-room');
      }
    };
  }, [contSocket, maxChats, idConversation, attendanceData]);

  const handleCountDown = (data: {
    id: number;
    conversation: Partial<ConversationType>;
  }) => {
    dispatch(updateConversation(data));
  };

  const handleJoinRoom = (conversation: ConversationType) => {
    if (
      maxChats ===
      chats.filter((item) => item.conversation.endTime == null).length
    ) {
      enqueueSnackbar(
        `Bạn chỉ được tiếp nhận ${maxChats} phiên chát hỗ trợ trong cùng 1 thời điểm`,
        { variant: 'error' },
      );
      return;
    }

    if (contSocket && authData && authData.user?.profile) {
      contSocket.emit('join-room-consultant-contact', {
        profile: authData.user.profile,
        conversation,
        content: `Xin chào, em là ${authData.user.profile.fullName} Nv tư vấn giải đáp trực tiếp cho Anh/Chị ạ`,
      });
      setIdConversation(conversation.id);
    }
    setOpenDrawer(false);
  };

  return (
    <>
      <Stack
        style={{
          cursor: 'pointer',
        }}
        alignItems={'center'}
        onClick={() => {
          setOpenDrawer(true);
          dispatch(closeAllChats(null));
        }}
        position={'relative'}
      >
        <IconSVG
          path={customIcon.chat}
          css={{ width: '22px', height: '22px', color: 'white' }}
        />
        {conversationsData.some(
          (conversation) =>
            conversation.endTime == null && conversation.startTime == null,
        ) && (
          <Box
            bgcolor={'red'}
            top={0}
            right={-4}
            width={10}
            height={10}
            position={'absolute'}
            borderRadius={5}
          />
        )}
      </Stack>
      <DrawerHeader
        totalItems={conversationsData.length}
        heightEachItem={100}
        toggleDrawer={(newOpen) => setOpenDrawer(newOpen)}
        title="Tin nhắn"
        open={openDrawer}
        heightDrawer={undefined}
        topArrow={openDrawer ? 67 : 80}
        rightArrow={120}
        background={'white'}
      >
        <>
          {conversationsData.map((item, key) => (
            <Conversation
              handleClick={() => handleJoinRoom(item)}
              handleCountDown={() =>
                handleCountDown({
                  id: item.id,
                  conversation: { endTime: getDateNow().toISOString() },
                })
              }
              conversation={item}
              key={key}
            />
          ))}

          <Box ref={inViewRef} />

          {isLoading && (
            <Stack width={'100%'} alignItems={'center'} mt={2}>
              <CircularProgress size={35} color="primary" />
            </Stack>
          )}
        </>
      </DrawerHeader>
    </>
  );
}
